import sxRut from "sx-rut";

export default {
    formatRut(rut) {
        return sxRut.formatRut(rut);
    },
    formatRutAndDigit(bodyPart, digitPart) {
        return sxRut.formatRutAndDigit(bodyPart, digitPart);
    }
}
