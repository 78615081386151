import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureStorage from "secure-web-storage";
import * as CryptoJS from "crypto-js";

import notifications from "@/store/modules/notifications";
import currentUser from "@/store/modules/currentUser";
import currentRegistration from "@/store/modules/currentRegistration";
import currentClaimInCreation from "@/store/modules/currentClaimInCreation";
import currentClaimSelected from "@/store/modules/currentClaimSelected";
import currentQueryInCreation from "@/store/modules/currentQueryInCreation";
import currentQuerySelected from "@/store/modules/currentQuerySelected";
import currentDashboard from "@/store/modules/currentDashboard";
import currentRecoveryPassword from "@/store/modules/currentRecoveryPassword";
import currentVerifyPhone from "@/store/modules/currentVerifyPhone";
import currentPagination from "@/store/modules/currentPagination";
import currentUpdateProfile from "@/store/modules/currentUpdateProfile";

Vue.use(Vuex);

const commandEncryptSessionStorage = Vue.prototype.$Settings.all.sessionStorage.encrypt.toString();
const keyForEncryptSessionStorage =
  Vue.prototype.$Settings.all.sessionStorage.encryptionKey;
const encryptSessionStorage = commandEncryptSessionStorage === "true";

//Configure store, persistant storage, crypto service
const option = {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, keyForEncryptSessionStorage);
    return key;
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, keyForEncryptSessionStorage);
    data = data.toString();
    return data;
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, keyForEncryptSessionStorage);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  },
};

const storageProvider = encryptSessionStorage
  ? new SecureStorage(sessionStorage, option)
  : sessionStorage;

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "this.notifications.data",
      paths: ["notifications"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentUser.data",
      paths: ["currentUser"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentRegistration.data",
      paths: ["currentRegistration"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentClaimInCreation.data",
      paths: ["currentClaimInCreation"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentClaimSelected.data",
      paths: ["currentClaimSelected"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentQueryInCreation.data",
      paths: ["currentQueryInCreation"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentQuerySelected.data",
      paths: ["currentQuerySelected"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentDashboard.data",
      paths: ["currentDashboard"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentRecoveryPassword.data",
      paths: ["currentRecoveryPassword"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentVerifyPhone.data",
      paths: ["currentVerifyPhone"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentPagination.data",
      paths: ["currentPagination"], //corresponde al modulo de store
      storage: storageProvider,
    }),
    createPersistedState({
      key: "this.app.currentUpdateProfile.data",
      paths: ["currentUpdateProfile"], //corresponde al modulo de store
      storage: storageProvider,
    }),
  ],

  modules: {
    notifications,
    currentUser,
    currentRegistration,
    currentClaimInCreation,
    currentQueryInCreation,
    currentClaimSelected,
    currentQuerySelected,
    currentDashboard,
    currentRecoveryPassword,
    currentVerifyPhone,
    currentPagination,
    currentUpdateProfile,
  },
  state: {},
  getters: {},
  actions: {},
  mutations: {},
});
