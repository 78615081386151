import { uuid } from "vue-uuid";

export default {
  namespaced: true,
  state: {
    dialogRelease: false,
    errorMiddleware: false,
    notifications: [],
  },
  mutations: {
    push(state, notification) {
      const not = state.notifications.find(
        (n) => n.message == notification.message
      );

      if (not == undefined || not == null)
        state.notifications.push(notification);
    },
    delete(state, notificationToRemove) {
      state.notifications = state.notifications.filter(
        (notif) => notif.id != notificationToRemove.id
      );
    },
    dialogConfirm(state) {
      state.dialogRelease = false;
    },
    setMiddleware(state, value) {
      state.errorMiddleware = value ?? true;
    },
  },
  actions: {
    addInfo({ commit }, message) {
      commit("push", { id: uuid.v4(), message: message, type: "info" });
    },
    addError({ commit }, message) {
      commit("push", { id: uuid.v4(), message: message, type: "error" });
    },
    remove({ commit }, notificationToRemove) {
      commit("delete", notificationToRemove);
    },
    dialogConfirm({ commit }) {
      commit("dialogConfirm");
    },
    setMiddleware({ commit }, value) {
      commit("setMiddleware", value);
    },
  },
  getters: {
    dialogRelease(state) {
      return state?.dialogRelease;
    },
    middleware(state) {
      return state?.errorMiddleware;
    },
  },
};
