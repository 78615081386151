import Vue from "vue";
import axios from "axios";
import axiosRetry from "axios-retry";

export default {
  getAll(parametersApiUrl) {

    const apiClientParametersSettings = axios.create({
      baseURL: `${parametersApiUrl}`,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
        "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`
      }
    });
    
    axiosRetry(apiClientParametersSettings, { 
        retries: 3,
        shouldResetTimeout: true,
        retryDelay: (retryCount) => {
            return retryCount * 1000;
        }
    });

    return new Promise((resolve) => {
        
      Promise.all([
        this.getBaseParameters(apiClientParametersSettings),
        this.getRegions(apiClientParametersSettings),
        this.getWfStages(apiClientParametersSettings),
        this.getWfStatuses(apiClientParametersSettings)
      ])
      .then(results => {

        let baseParameters = results[0].data;
        baseParameters.push({
          type: "Regions",
          values: results[1].data
        });

        baseParameters.push({
          type: "WfStages",
          values: results[2].data
        }); 

        baseParameters.push({
          type: "WfStatuses",
          values: results[3].data
        });

        resolve(baseParameters);
      })

    });
  },
  getBaseParameters(axiosApiClient) {
    return axiosApiClient.get("/parameters");
  },
  getRegions(axiosApiClient) {
    return axiosApiClient.get("/regions");
  },
  getWfStages(axiosApiClient) {
    return axiosApiClient.get("/stages");
  },
  getWfStatuses(axiosApiClient) {
    return axiosApiClient.get("/states");
  }
}