const defaultDashboard = {
  ddcExecutive: {
    claimsToAdmit: 0, //Por revisar
    claimsPendingAssignment: 0, //Por asignar
    claimsSendPapers: 0, //Antecedentes adicionales pendientes
    claimsPendingClient: 0, //Pendiente cliente
    claimsPendingBank: 0, //pendiente banco
    claimsPendingDefender: 0, //pendiente defensor
    claimsClosed: 0, //cerrados
    queriesPending: 0, //Por revisar
    queriesClosed: 0, //cerrados
  },
  ddcDefender: {
    claimsAssigned: 0, //Pendiente defensor
    claimsNotCompatible: 0, //Incompatibles,
    claimsSendPapers: 0, //Antecedentes adicionales,
    claimsPendingClient: 0, //Pendiente Cliente,
    claimsPendingBank: 0, //Pendiente Banco
    claimsClosed: 0, //Cerrados
  },
  bankAdministrator: {
    claimsCreated: 0, //Ingresados
    claimsForAnswer: 0, //Por responder
    claimsInExtension: 0, //Prorrogables
    claimsSendPapers: 0, //Antecedentes adicionales,
    claimsPendingClient: 0, //Pendiente Cliente,
    claimsPendingDDC: 0, //Pendiente ejecutivo
    claimsPendingDefender: 0, //Pendiente defensor
    claimsClosed: 0, //Cerrados
  },
  bankExecutive: {
    claimsCreated: 0, //Ingresados
    claimsPendingResponse: 0, //Por responder
    claimsInExtension: 0, //Prorrogables
    claimsSendPapers: 0, //Antecedentes adicionales,
    claimsPendingClient: 0, //Pendiente Cliente,
    claimsPendingDDC: 0, //Pendiente ejecutivo
    claimsPendingDefender: 0, //Pendiente defensor
    claimsClosed: 0, //Cerrados
  },
};

export default {
  namespaced: true,
  state: {
    actualDashboard: defaultDashboard,
  },
  mutations: {
    setCurrentDashboard(state, dashboardData) {
      state.actualDashboard = dashboardData;
    },
    deleteCurrentDashboard(state) {
      state.actualDashboard = defaultDashboard;
    },
  },
  actions: {
    createOrUpdate({ commit }, dashboardData) {
      commit("setCurrentDashboard", dashboardData);
    },
    clear({ commit }) {
      commit("deleteCurrentDashboard");
    },
  },
  getters: {
    currentDashboard(state) {
      return state?.actualDashboard;
    },
  },
};
