import { DateTime } from "luxon";

//Default locale
const defaultLocale = "es-CL";

//Dates formats
const shortDateFormat = { year: "numeric", month: "2-digit", day: "2-digit" }; //example:  11/03/2021
const fullDateFormat = DateTime.DATE_FULL; //example:  11 de marzo de 2021
const longFullDateFormat = DateTime.DATE_HUGE; //example:  jueves, 11 de marzo de 2021
const customShortDateFormat = "dd/LL/yyyy"; //example:  11/03/2021

//Times formats
const shortTimeFormat = { hour: "2-digit", minute: "2-digit" }; //example:  20:52
const shortTimeFormatWithSeconds = {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
}; //example:  20:52:34

export default {
  formatDateShort(isoStringDate, locale) {
    return DateTime.fromISO(isoStringDate)
      .setLocale(locale ? locale : defaultLocale)
      .toLocaleString(shortDateFormat);
  },
  formatDateFull(isoStringDate, locale) {
    return DateTime.fromISO(isoStringDate)
      .setLocale(locale ? locale : defaultLocale)
      .toLocaleString(fullDateFormat);
  },
  formatDateLongFull(isoStringDate, locale) {
    return DateTime.fromISO(isoStringDate)
      .setLocale(locale ? locale : defaultLocale)
      .toLocaleString(longFullDateFormat);
  },
  formatDateCustom(isoStringDate, locale) {
    return DateTime.fromISO(isoStringDate)
      .setLocale(locale ? locale : defaultLocale)
      .toFormat(customShortDateFormat);
  },
  formatTimeShort(isoStringDate, locale) {
    return DateTime.fromISO(isoStringDate)
      .setLocale(locale ? locale : defaultLocale)
      .toLocaleString(shortTimeFormat);
  },
  formatTimeShortWithSeconds(isoStringDate, locale) {
    return DateTime.fromISO(isoStringDate)
      .setLocale(locale ? locale : defaultLocale)
      .toLocaleString(shortTimeFormatWithSeconds);
  },
  formatDaysFromHours(hours) {
    if (hours <= 24) {
      if (hours > 0) return `${hours} horas`;
      else {
        let day = hours / 24;
        day = parseInt(day);
        const dayNormal = day * 24;
        const rest = dayNormal - hours;

        if (day == 0) return `-${rest} horas`;
        else {
          if (rest == 0) return `${parseInt(day)} días`;
          else return `${parseInt(day)} días ${rest} hrs`;
        }
      }
    }

    if (hours > 24) {
      let day = hours / 24;
      day = parseInt(day);
      const dayNormal = day * 24;
      const rest = hours - dayNormal;

      if (rest == 0) return `${parseInt(day)} días`;
      else return `${parseInt(day)} días ${rest} hrs`;
    }

    return ``;
  },
};
