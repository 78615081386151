import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const dateTimeFormats = {
    'es': {
      short: {
        year: 'numeric', month: '2-digit', day: '2-digit'
      },
      long: {
        year: 'numeric', month: 'long', day: 'numeric',
        weekday: 'long', hour: 'numeric', minute: 'numeric'
      },
      longSpecial: {
        year: 'numeric', month: 'short', day: 'numeric',
        weekday: 'short', hour: 'numeric', minute: 'numeric'
      },
      justHour: {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    },
};

const numberFormats = {
    'es': {
        percent: {
            style: 'percent'
        },
        currency: {
            style: 'currency', currency: 'CLP'
        },
        currencyWithCents: {
            style: 'currency', currency: 'CLP', minimumFractionDigits: 2, maximumFractionDigits: 2
        }
    }
};

/* 
https://github.com/kazupon/vue-i18n/blob/dev/decls/i18n.js

https://es.wikipedia.org/wiki/ISO_8601
*/

export default new VueI18n({
    locale: "es-CL",
    dateTimeFormats: dateTimeFormats,
    numberFormats: numberFormats
});