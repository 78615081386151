import environmentSettings from "@/plugins/environmentSettings";

environmentSettings.get(startUp);

function startUp() {
  const mainend = require("@/main.complement"); //Con este truco lo carga en este preciso momento. No antes.
  
  if (process.env.NODE_ENV == "development") {
    console.log("main.js.mainend", mainend);
  }
}