import authenticationConstants from "@/plugins/authenticationConstants";

export default {
    data() {
        return {
            authenticatedUserInfo: {},
            authenticatedFullUserInfo: {}
        }
    },
    computed: {
        currentUserIsAuthenticated() {
          return this.$Authentication.getAccount() ? true : false;
        },
        currentSessionId() {
            return this.$store.getters["currentUser/sessionId"];
        },
        currentUserInfo() {
            return this.$Authentication.getBasicAccountInfo();
        },
        currentFullUserInfo() {
            return this.$Authentication.getFullAccountInfo();
        },
        currentUserMustChangePassword() {
            return this.$store.getters["currentUser/userMustChangePassword"];
        },
        currentUserRoleName() {
            if (!this.currentUserIsAuthenticated)
                return null;

            const userRole = this.currentUserInfo.roles[0];

            if (userRole == authenticationConstants.userRolesCodes.ddcExecutive)
                return "Ejecutivo DDC";

            if (userRole == authenticationConstants.userRolesCodes.ddcDefender)
                return "Defensor DDC";
            
            if (userRole == authenticationConstants.userRolesCodes.bankExecutive)
                return "Ejecutivo banco ";

            if (userRole == authenticationConstants.userRolesCodes.bankAdministrator)
                return "Administrador banco ";

            return userRole;    
        },
        currentSessionIsTimedOut() {
            return this.$store.getters["currentUser/isSesionTimeout"];
        }
    },
    methods: {
        internalLogin(userName, password) {
            return this.$Authentication.login(userName, password);
        },
        internalLogout() {
            this.$Authentication.logout()
            sessionStorage.clear();
            return;
        },
        internalPostLogin() {
            return this.$Authentication.postLogin();
        },
        markPasswordAsChanged() {
            this.$store.dispatch("currentUser/markPasswordAsChanged");
        },
        markSessionAsTimedOut() {
            this.$store.dispatch("currentUser/markSessionAsTimedOut");
        }
    }
};