const claimant = {
    collectBasicData: 1,
    collectAddressData: 2
};

const wfUser = {
    default: 1,
};

export default {
    claimant,
    wfUser
};