import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3d91ab",
        secondary: "#6cc551",
        accent: "#9ffcdf",
        error: "#e91e63",
        warning: "#f44336",
        info: "#4CAF50",
        success: "#52AD9C",
      },
    },
  },
});

export default vuetify;
