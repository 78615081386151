import regexConstants from "@/constants/regexconstants";

export default {
    mask(email) {
        if (!regexConstants.RegexEmail.test(email))
            return email;

        const emailData = email.trim().split('@');
        const name = emailData[0];
        const fullDomain = emailData[1];
        const subDomainSeparatorPosition = fullDomain.lastIndexOf('.');

        let maskedName = name.length > 4 ? `${name.substr(0, 4)}${'x'.repeat(name.substr(4).length)}` : name;
        let maskedDomain;

        if (subDomainSeparatorPosition == -1)
            maskedDomain = fullDomain;
        else {
            maskedDomain = `${'x'.repeat(subDomainSeparatorPosition)}${fullDomain.substr(subDomainSeparatorPosition)}`;
        }
        
        return `${maskedName}@${maskedDomain}`;
    }
}