export default {
    namespaced : true,
    state: {
        currentUser: null,
        isSesionTimeout: false
    },
    mutations: { 
        setCurrentUser(state, {user, sessionId, userMustChangePassword: userMustChangePassword}) {
            state.currentUser = {user: user, sessionId: sessionId, userMustChangePassword: userMustChangePassword};
            state.isSesionTimeout = false;
        },
        deleteCurrentUser(state) {
            state.currentUser = null;
            state.isSesionTimeout = false;
            sessionStorage.clear();
        },
        markPasswordAsChanged(state) {
            state.currentUser.userMustChangePassword = false;
        },
        updateCurrentUser(state, user) {
            state.currentUser.user = user;
        },
        markSessionAsTimedOut(state) {
            state.isSesionTimeout = true;
        },
    },
    actions: {  
        login({commit}, user, sessionId, userMustChangePassword) {
            commit("setCurrentUser", user, sessionId, userMustChangePassword);
        },
        logout({commit}) {
            commit("deleteCurrentUser");    
        },
        markPasswordAsChanged({commit}) {
            commit("markPasswordAsChanged");
        },
        updateCurrentUser({commit}, user) {
            commit("updateCurrentUser", user);
        },
        markSessionAsTimedOut({commit}) {
            commit("markSessionAsTimedOut");
        },
    },
    getters: {        
        sessionId(state) {
            return state?.currentUser?.sessionId;
        },
        userInfo(state) {
            return state?.currentUser?.user;
        },
        userMustChangePassword(state) {
            return state?.currentUser?.userMustChangePassword;
        },
        isSesionTimeout(state) {
            return state?.isSesionTimeout;
        }
    }
}