export default {
  namespaced: true,
  state: {
    currentQuery: null,
  },
  mutations: {
    setCurrentQuery(state, query) {
      state.currentQuery = query;
    },
    deleteCurrentQuery(state) {
      state.currentQuery = null;
    },
  },
  actions: {
    createOrUpdate({ commit }, query) {
      commit("setCurrentQuery", query);
    },
    clear({ commit }) {
      commit("deleteCurrentQuery");
    },
  },
  getters: {
    currentQuery(state) {
      return state?.currentQuery;
    },
  },
};
