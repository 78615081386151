import Vue from "vue";

import axios from "axios";
import axiosRetry from "axios-retry";

import { uuid } from "vue-uuid";
import authenticationConstants from "@/plugins/authenticationConstants";

import store from "@/store/index";
import router from "@/router/index";

class AuthService {
  async login(userName, password) {
    const apiAccounts = axios.create({
      baseURL: `${Vue.prototype.$Settings.all.accountsApi.url}/useraccounts`,
      withCredentials: true, //true para soportar cookies de respuests
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
        "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
      },
    });

    axiosRetry(apiAccounts, {
      retries: 3,
      shouldResetTimeout: true,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
    });

    let loginResult;
    try {
      loginResult = await apiAccounts.post("/Login/", {
        userName: userName,
        password: password,
      });
    } catch (error) {
      return {
        resultCode: 6,
        totalRetries: 0,
        retryCount: 0,
      };
    }

    const loginResultData = loginResult.data;

    if (
      loginResultData.resultCode !=
        authenticationConstants.loginResultCode.Success &&
      loginResultData.resultCode !=
        authenticationConstants.loginResultCode.SuccessAndMustChangePassword
    ) {
      return {
        resultCode: loginResultData.resultCode,
        totalRetries: loginResultData.totalRetries,
        retryCount: loginResultData.retryCount,
      };
    }

    const userAndSession = {
      user: {
        id: loginResultData.account.id,
        userName: loginResultData.account.identificationNumber,
        visiblename: `${loginResultData.account.name} ${loginResultData.account.surname}`,
        name: loginResultData.account.name,
        surname: loginResultData.account.surname,
        email: loginResultData.account.email,
        roles: [loginResultData.account.roleId],
        phone: loginResultData.account.phone,
        cellphone: loginResultData.account.cellphone,
        address: loginResultData.account.address,
        bank: loginResultData.account.bank,
        userType: loginResultData.account.userType,
        token: loginResultData.token,
        expires: loginResultData.expires,
      },
      sessionId: uuid.v4(),
      userMustChangePassword:
        loginResultData.resultCode ==
        authenticationConstants.loginResultCode.SuccessAndMustChangePassword,
    };

    // --- Steps post "real login"  ---
    Vue.prototype.$Trace.setCurrentUser(
      userAndSession.user.userName,
      userAndSession.user.id,
      userAndSession.sessionId
    );
    store.dispatch("currentUser/login", userAndSession);
    // --------------------------------

    return {
      resultCode: loginResultData.resultCode,
    };
  }

  postLogin() {
    const userRoles = this.getAccount().roles;

    if (Vue.prototype.$Settings.all.excecutionMode == "WF") {
      if (
        userRoles.some(
          (role) => role == authenticationConstants.userRolesCodes.claimant
        )
      ) {
        this.logout();
        store.dispatch("notifications/addError", "Rut o contraseña incorrecta");
      } else router.push({ name: "dashboard" });
    }

    if (Vue.prototype.$Settings.all.excecutionMode == "FRONT") {
      if (
        userRoles.some(
          (role) => role == authenticationConstants.userRolesCodes.claimant
        )
      )
        router.push({ name: "resume" });
      else {
        this.logout();
        store.dispatch("notifications/addError", "Rut o contraseña incorrecta");
      }
    }
  }

  logout() {
    // --- Steps post "real logout" ---
    Vue.prototype.$Trace.deleteCurrentUser();
    store.dispatch("currentUser/logout");
    // --------------------------------

    if (Vue.prototype.$Settings.all.excecutionMode == "FRONT") {
      router.push({ name: "begin" });
    }

    if (Vue.prototype.$Settings.all.excecutionMode == "WF") {
      router.push({ name: "login" });
    }
  }

  getAccount() {
    return store.getters["currentUser/userInfo"];
  }

  getSessionId() {
    return store.getters["currentUser/sessionId"];
  }

  getBasicAccountInfo() {
    return store.getters["currentUser/userInfo"];
  }

  getFullAccountInfo() {
    return store.getters["currentUser/userInfo"];
  }

  updateCurrentUserInfo(newAccountData) {
    const actualUserData = this.getAccount();
    actualUserData.name = newAccountData.name;
    actualUserData.surname = newAccountData.surname;
    actualUserData.visiblename = `${newAccountData.name} ${newAccountData.surname}`;
    actualUserData.email = newAccountData.email;
    actualUserData.phone = newAccountData.phone;
    actualUserData.cellphone = newAccountData.cellphone;
    actualUserData.address.region = newAccountData.address.region;
    actualUserData.address.city = newAccountData.address.city;
    actualUserData.address.commune = newAccountData.address.commune;
    actualUserData.address.streetAddress = newAccountData.address.streetAddress;
    actualUserData.address.apartment = newAccountData.address.apartment;
    actualUserData.address.building = newAccountData.address.building;

    store.dispatch("currentUser/updateCurrentUser", actualUserData);
  }
}

export default {
  install(Vue) {
    Vue.prototype.$Authentication = new AuthService();
  },
};
