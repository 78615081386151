export default {
    namespaced : true,
    state: {
        currentPage: null
    },
    mutations: { 
        setCurrentPage(state, pageInfo) {
            state.currentPage = pageInfo;
        },
        deleteCurrentPage(state) {
            state.currentPage = null;
        },
    },
    actions: {  
        createOrUpdate({commit}, pageInfo) {
            commit("setCurrentPage", pageInfo);
        },
        clear({commit}) {
            commit("deleteCurrentPage");    
        },
    },
    getters: {        
        currentPage(state) {
            if (state?.currentPage)
                return state?.currentPage;
            else
                return {
                    routerName: "",
                    totalPages: 0,
                    totalRecords: 0,
                    pageNumber: 1, 
                    extension: null
                }
        }
    }
}