const patternGuid =
  "^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1})$";
const patternEmail =
  "^[_a-zA-Z0-9-]+(.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*(.[a-zA-Z]{2,4})$";
const patternDigits = "^([0-9.,]*)$";
const patternName = "^([a-zA-Z áÁ éÉ íÍ óÓ úÚ ñÑ,.'-]+)$";
const patternDescription = "^([0-9a-zA-Z áÁ éÉ íÍ óÓ úÚ ñÑ,_.'-]+)$";
const patternComments =
  "^([0-9a-zA-Z@\n=()$%#°¿?!¡\"&*/|¬{}áÁ éÉ íÍ óÓ úÚ ñÑ,;_.'+-:]*)$";
const patternPhone = "^([0-9]*)$";
const patternLetters = "^([A-Za-z]*)$";
const patternRut = "^((([0-9]{1,8}))(([0-9]{1,1})|(k{1,1})|(K{1,1})))$";
const patternFileNameSimple =
  "^((([a-zA-Z0-9.-]{1,50})(\\.){1,1}([a-zA-Z]{1,5}))?)$";
const patternFileNameComplex =
  "^([0-9a-zA-Z@\n=()$%#°¿?!¡\"&*/|¬{}áÁ éÉ íÍ óÓ úÚ ñÑ,;_.'+-:]*)$";

const RegexGuid = new RegExp(patternGuid);
const RegexEmail = new RegExp(patternEmail);
const RegexDigits = new RegExp(patternDigits);
const RegexName = new RegExp(patternName);
const RegexDescription = new RegExp(patternDescription);
const RegexComments = new RegExp(patternComments);
const RegexPhone = new RegExp(patternPhone);
const RegexLetters = new RegExp(patternLetters);
const RegexRut = new RegExp(patternRut);
const RegexFileNameSimple = new RegExp(patternFileNameSimple);
const RegexFileNameComplex = new RegExp(patternFileNameComplex);

export default {
  patternGuid,
  patternEmail,
  patternDigits,
  patternName,
  patternDescription,
  patternComments,
  patternPhone,
  patternLetters,
  patternRut,
  RegexGuid,
  RegexEmail,
  RegexDigits,
  RegexName,
  RegexDescription,
  RegexComments,
  RegexPhone,
  RegexLetters,
  RegexRut,
  RegexFileNameSimple,
  RegexFileNameComplex,
};
