import Vue from "vue";
import VueRouter from "vue-router";

import authenticationConstants from "@/plugins/authenticationConstants";
import executionConstants from "@/constants/execution";

Vue.use(VueRouter);

const routesPublicSite = [
  {
    path: "/",
    name: "begin",
    component: () => import("../views/PublicSite/home.vue"),
    children: [
      {
        path: "/defensores",
        name: "defenders",
        component: () => import("../views/PublicSite/defenders.vue"),
      },
      {
        path: "/asociados",
        name: "associates",
        component: () => import("../views/PublicSite/associates.vue"),
      },
      {
        path: "/estatutos",
        name: "statutes",
        component: () => import("../views/PublicSite/statutes.vue"),
      },
      {
        path: "/preguntas",
        name: "questions",
        component: () => import("../views/PublicSite/questions.vue"),
      },
      {
        path: "/contacto",
        name: "contact",
        component: () => import("../views/PublicSite/contact.vue"),
      },
      //routers asociados al flujo de registro
      {
        path: "/tipousuarioregistro",
        name: "registrationSelectionUserType",
        component: () =>
          import("../views/PublicSite/Registration/selectionUserType.vue"),
      },
      {
        path: "/registro",
        name: "registrationFlow",
        component: () =>
          import("../views/PublicSite/Registration/registrationFlow.vue"),
      },
      //routers asociados al flujo de creación de un reclamo
      {
        path: "/registrado",
        name: "selectionUserIsRegistered",
        component: () =>
          import(
            "../views/PublicSite/ClaimCreation/selectionUserIsRegistered.vue"
          ),
      },
      {
        path: "/tipousuario",
        name: "selectionUserType",
        component: () =>
          import("../views/PublicSite/ClaimCreation/selectionUserType.vue"),
      },
      {
        path: "/creacionreclamo",
        name: "claimCreationFlow",
        component: () =>
          import("../views/PublicSite/ClaimCreation/creationFlow.vue"),
      },
      //routers asociados al flujo de creación de una consulta
      {
        path: "/registradoConsulta",
        name: "selectionUserIsRegisteredQuery",
        component: () =>
          import(
            "../views/PublicSite/QueryCreation/selectionUserIsRegistered.vue"
          ),
      },
      {
        path: "/tipousuarioConsulta",
        name: "selectionUserTypeQuery",
        component: () =>
          import("../views/PublicSite/QueryCreation/selectionUserType.vue"),
      },
      {
        path: "/creacionconsulta",
        name: "queryCreationFlow",
        component: () =>
          import("../views/PublicSite/QueryCreation/creationFlow.vue"),
      },
    ],
  },
];

//MAIN SECTION
const executionMode = Vue.prototype.$Settings.all.excecutionMode;
const routesInitial = [];

if (executionMode == executionConstants.mode.FRONT) {
  Array.prototype.push.apply(routesInitial, routesPublicSite);
} else {
  routesInitial.push({
    path: "/",
    name: "login",
    component: () => import("../views/WFSite/Login.vue"),
  });
}

const routesPublicSitePrivate = [
  {
    path: "/main",
    name: "main",
    component: () => import("../views/PublicSite/User/Main.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/resumen",
        name: "resume",
        component: () => import("../views/PublicSite/User/Resume.vue"),
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: "/reclamos",
        name: "claims",
        component: () => import("../views/PublicSite/User/Claims.vue"),
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: "/consultas",
        name: "queries",
        component: () => import("../views/PublicSite/User/Queries.vue"),
        meta: { requiresAuth: true },
        props: true,
      },
      {
        path: "/detalleconsulta",
        name: "querydetail",
        component: () => import("../views/PublicSite/User/QueryDetail.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/detallereclamo",
        name: "claimdetail",
        component: () => import("../views/PublicSite/User/ClaimDetail.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/editarperfil",
        name: "userProfileEdit",
        component: () => import("../views/PublicSite/User/EditProfileFlow.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/exit",
    name: "logoutfront",
    component: () => import("../views/PublicSite/User/Logout.vue"),
    meta: { requiresAuth: true },
  },
];

const routesWFSite = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/WFSite/Dashboard.vue"),
    meta: {
      requiresAuth: true,
      requiresRole: [
        authenticationConstants.userRolesCodes.ddcExecutive,
        authenticationConstants.userRolesCodes.ddcDefender,
        authenticationConstants.userRolesCodes.bankAdministrator,
        authenticationConstants.userRolesCodes.bankExecutive,
      ],
    },
    children: [
      {
        path: "/construction",
        name: "construction",
        component: () => import("../views/WFSite/Construction.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.ddcExecutive,
            authenticationConstants.userRolesCodes.ddcDefender,
            authenticationConstants.userRolesCodes.bankAdministrator,
            authenticationConstants.userRolesCodes.bankExecutive,
          ],
        },
        props: true,
      },
      //Vistas del Ejecutivo DDC
      {
        path: "/ejecutivoddc/reclamosIngresados",
        name: "ddcExecutive-claimsToAdmit",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/ClaimsToAdmit.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reclamosPorAsignar",
        name: "ddcExecutive-claimsPendingAssignment",
        component: () =>
          import(
            "../views/WFSite/Claims/DDCExecutive/ClaimsPendingAssignment.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reclamosAtencedentesAdicionales",
        name: "ddcExecutive-claimsSendPapers",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/ClaimsSendPapers.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reclamosPendienteCliente",
        name: "ddcExecutive-claimsPendingClient",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/ClaimsPendingClient.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reclamosPendienteBanco",
        name: "ddcExecutive-claimsPendingBank",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/ClaimsPendingBank.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reclamosPendienteDefensor",
        name: "ddcExecutive-claimsPendingDefender",
        component: () =>
          import(
            "../views/WFSite/Claims/DDCExecutive/ClaimsPendingDefender.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reclamosResueltos",
        name: "ddcExecutive-claimsClosed",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/ClaimsClosed.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/informe",
        name: "ddcExecutive-claimReport",
        component: () => import("../views/WFSite/Claims/Report/Test.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reporte/reclamosFinalizados",
        name: "ddcExecutive-report-claims-closed",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/Report/ClaimsClosed.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reporte/reclamosParticipacionPorcentual",
        name: "ddcExecutive-report-claims-closed-percentage",
        component: () =>
          import(
            "../views/WFSite/Claims/DDCExecutive/Report/ClaimsClosedPercentage.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reporte/reclamosPorBanco",
        name: "ddcExecutive-report-claims-for-bank",
        component: () =>
          import(
            "../views/WFSite/Claims/DDCExecutive/Report/ClaimsForBank.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reporte/reclamosTramitadosYRechazados",
        name: "ddcExecutive-report-claims-all-state",
        component: () =>
          import(
            "../views/WFSite/Claims/DDCExecutive/Report/ClaimsAllState.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reporte/reclamosPorTipologia",
        name: "ddcExecutive-report-claims-typologies",
        component: () =>
          import(
            "../views/WFSite/Claims/DDCExecutive/Report/ClaimsTypologies.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/reporte/reclamosPorDefensor",
        name: "ddcExecutive-report-claims-defenders",
        component: () =>
          import(
            "../views/WFSite/Claims/DDCExecutive/Report/ClaimsDefenders.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/consultasIngresadas",
        name: "ddcExecutive-queriesPending",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/QueriesPending.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoddc/consultasCerradas",
        name: "ddcExecutive-queriesClosed",
        component: () =>
          import("../views/WFSite/Claims/DDCExecutive/QueriesClosed.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
        props: true,
      },
      //Vistas del Administrador Banco
      {
        path: "/adminBanco/reclamosIngresados",
        name: "bankAdministrator-claimsCreated",
        component: () =>
          import("../views/WFSite/Claims/BankAdministrator/ClaimsCreated.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/PorResponder",
        name: "bankAdministrator-claimsForAnswer",
        component: () =>
          import(
            "../views/WFSite/Claims/BankAdministrator/ClaimsForAnswer.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/prorrogables",
        name: "bankAdministrator-claimsInExtension",
        component: () =>
          import(
            "../views/WFSite/Claims/BankAdministrator/ClaimsInExtension.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/reclamosAtencedentesAdicionales",
        name: "bankAdministrator-claimsSendPapers",
        component: () =>
          import(
            "../views/WFSite/Claims/BankAdministrator/ClaimsSendPapers.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/reclamosPendienteCliente",
        name: "bankAdministrator-claimsPendingClient",
        component: () =>
          import(
            "../views/WFSite/Claims/BankAdministrator/ClaimsPendingClient.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/reclamosPendienteEjecutivoDDC",
        name: "bankAdministrator-claimsPendingDDC",
        component: () =>
          import(
            "../views/WFSite/Claims/BankAdministrator/ClaimsPendingDDC.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/reclamosPendienteDefensor",
        name: "bankAdministrator-claimsPendingDefender",
        component: () =>
          import(
            "../views/WFSite/Claims/BankAdministrator/ClaimsPendingDefender.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/reclamosResueltos",
        name: "bankAdministrator-claimsClosed",
        component: () =>
          import("../views/WFSite/Claims/BankAdministrator/ClaimsClosed.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      {
        path: "/adminBanco/informe",
        name: "bankAdministrator-claimReport",
        component: () =>
          import("../views/WFSite/Claims/BankAdministrator/ClaimsReport.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.bankAdministrator,
          ],
        },
        props: true,
      },
      //Vistas del Ejecutivo Banco
      {
        path: "/ejecutivoBanco/reclamosIngresados",
        name: "bankExecutive-claimsCreated",
        component: () =>
          import("../views/WFSite/Claims/BankExecutive/ClaimsCreated.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoBanco/porResponder",
        name: "bankExecutive-claimsPendingResponse",
        component: () =>
          import(
            "../views/WFSite/Claims/BankExecutive/ClaimsPendingResponse.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoBanco/prorrogables",
        name: "bankExecutive-claimsInExtension",
        component: () =>
          import("../views/WFSite/Claims/BankExecutive/ClaimsInExtension.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoBanco/reclamosAtencedentesAdicionales",
        name: "bankExecutive-claimsSendPapers",
        component: () =>
          import("../views/WFSite/Claims/BankExecutive/ClaimsSendPapers.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoBanco/reclamoPendienteCliente",
        name: "bankExecutive-claimsPendingClient",
        component: () =>
          import(
            "../views/WFSite/Claims/BankExecutive/ClaimsPendingClient.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoBanco/reclamoPendienteEjecutivoDDC",
        name: "bankExecutive-claimsPendingDDC",
        component: () =>
          import("../views/WFSite/Claims/BankExecutive/ClaimsPendingDDC.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoBanco/reclamoPendienteDefensor",
        name: "bankExecutive-claimsPendingDefender",
        component: () =>
          import(
            "../views/WFSite/Claims/BankExecutive/ClaimsPendingDefender.vue"
          ),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      {
        path: "/ejecutivoBanco/resueltos",
        name: "bankExecutive-claimsClosed",
        component: () =>
          import("../views/WFSite/Claims/BankExecutive/ClaimsClosed.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.bankExecutive],
        },
        props: true,
      },
      //Vistas del Defensor DDC
      {
        path: "/defensorddc/reclamosAsignados",
        name: "ddcDefender-claimsAssigned",
        component: () =>
          import("../views/WFSite/Claims/DDCDefender/ClaimsAssigned.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcDefender],
        },
        props: true,
      },
      {
        path: "/defensorddc/reclamosIncompatibles",
        name: "ddcDefender-claimsNotCompatible",
        component: () =>
          import("../views/WFSite/Claims/DDCDefender/ClaimsNotCompatible.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcDefender],
        },
        props: true,
      },
      {
        path: "/defensorddc/reclamosAtencedentesAdicionales",
        name: "ddcDefender-claimsSendPapers",
        component: () =>
          import("../views/WFSite/Claims/DDCDefender/ClaimsSendPapers.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcDefender],
        },
        props: true,
      },
      {
        path: "/defensorddc/reclamosPendienteCliente",
        name: "ddcDefender-claimsPendingClient",
        component: () =>
          import("../views/WFSite/Claims/DDCDefender/ClaimsPendingClient.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcDefender],
        },
        props: true,
      },
      {
        path: "/defensorddc/reclamosPendienteBanco",
        name: "ddcDefender-claimsPendingBank",
        component: () =>
          import("../views/WFSite/Claims/DDCDefender/ClaimsPendingBank.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcDefender],
        },
        props: true,
      },
      {
        path: "/defensorddc/reclamosResueltos",
        name: "ddcDefender-claimsClosed",
        component: () =>
          import("../views/WFSite/Claims/DDCDefender/ClaimsClosed.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcDefender],
        },
        props: true,
      },
      //General, detalle del reclamo
      {
        path: "/detalle-reclamo",
        name: "claimDetailInWf",
        component: () => import("../views/WFSite/Claims/ClaimDetail.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [
            authenticationConstants.userRolesCodes.ddcExecutive,
            authenticationConstants.userRolesCodes.ddcDefender,
            authenticationConstants.userRolesCodes.bankAdministrator,
            authenticationConstants.userRolesCodes.bankExecutive,
          ],
        },
      },
      //General, detalle de la consulta
      {
        path: "/detalle-consulta",
        name: "queryDetailInWf",
        component: () => import("../views/WFSite/Claims/QueryDetail.vue"),
        meta: {
          requiresAuth: true,
          requiresRole: [authenticationConstants.userRolesCodes.ddcExecutive],
        },
      },
    ],
  },
  {
    path: "/profile",
    name: "editprofile",
    component: () => import("../views/WFSite/EditProfile.vue"),
    meta: {
      requiresAuth: true,
      requiresRole: [
        authenticationConstants.userRolesCodes.ddcExecutive,
        authenticationConstants.userRolesCodes.ddcDefender,
        authenticationConstants.userRolesCodes.bankAdministrator,
        authenticationConstants.userRolesCodes.bankExecutive,
      ],
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/WFSite/Logout.vue"),
    meta: {
      requiresAuth: true,
      requiresRole: [
        authenticationConstants.userRolesCodes.ddcExecutive,
        authenticationConstants.userRolesCodes.ddcDefender,
        authenticationConstants.userRolesCodes.bankAdministrator,
        authenticationConstants.userRolesCodes.bankExecutive,
      ],
    },
  },
  {
    path: "/crearreclamo",
    name: "claimCreationFlowInWf",
    component: () => import("../views/WFSite/ClaimCreationFlow.vue"),
    meta: {
      requiresAuth: true,
      requiresRole: [
        authenticationConstants.userRolesCodes.ddcExecutive,
        authenticationConstants.userRolesCodes.ddcDefender,
        authenticationConstants.userRolesCodes.bankAdministrator,
        authenticationConstants.userRolesCodes.bankExecutive,
      ],
    },
  },
];

const routesAtEnd = [
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
];

const allRoutes = routesInitial.concat(
  routesPublicSitePrivate,
  routesWFSite,
  routesAtEnd
);

const routerInstance = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: allRoutes,
});

routerInstance.beforeEach(async (to, from, next) => {
  const userIsAuthenticated = Vue.prototype.$Authentication.getAccount();
  const userAccountInfo = await Vue.prototype.$Authentication.getBasicAccountInfo();

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !userIsAuthenticated
  ) {
    next("/");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresRole)) {
    const userIsInRoles = to.meta.requiresRole.find(
      (requiredrole) =>
        requiredrole ==
        userAccountInfo?.roles.find((userRole) => userRole == requiredrole)
    );

    if (!userIsInRoles) {
      next("/notallowed");
      return;
    }
  }

  next();
});

export default routerInstance;
