import authenticationConstants from "@/plugins/authenticationConstants";
import claimsConstants from "@/constants/claims";
import queriesConstants from "@/constants/queries";
import registrationConstants from "@/constants/registration";
import regexConstants from "@/constants/regexconstants";
import challengesConstants from "@/constants/challenges";
import updateProfileConstants from "@/constants/updateProfile";
import dashboardConstants from "@/constants/dashboard";

export default {
  computed: {
    allConstants() {
      return {
        claims: {
          chanelCodes: claimsConstants.chanelCodes,
          creationSteps: claimsConstants.creationSteps,
          documentStatuses: claimsConstants.documentStatuses,
          posibleActions: claimsConstants.posibleActions,
          rejectionByDeepReasonsCodes:
            claimsConstants.rejectionByDeepReasonsCodes,
          currentEventActions: claimsConstants.currentEventActions,
          wfStatusesForDisplay: claimsConstants.wfStatusesForDisplay,
        },
        queries: {
          chanelCodes: queriesConstants.chanelCodes,
          creationSteps: queriesConstants.creationSteps,
          documentStatuses: queriesConstants.documentStatuses,
          posibleActions: queriesConstants.posibleActions,
          rejectionByDeepReasonsCodes:
            queriesConstants.rejectionByDeepReasonsCodes,
          currentEventActions: queriesConstants.currentEventActions,
          wfStatusesForDisplay: queriesConstants.wfStatusesForDisplay,
        },
        registration: {
          registrationSteps: registrationConstants.registrationSteps,
        },
        security: {
          userRoles: authenticationConstants.userRoles,
          userRolesCodes: authenticationConstants.userRolesCodes,
          userTypes: authenticationConstants.userTypes,
          loginResultCode: authenticationConstants.loginResultCode,
          changePasswordResultCode:
            authenticationConstants.changePasswordResultCode,
        },
        regex: {
          regexGuid: regexConstants.RegexGuid,
          regexEmail: regexConstants.RegexEmail,
          regexDigits: regexConstants.RegexDigits,
          regexName: regexConstants.RegexName,
          regexDescription: regexConstants.RegexDescription,
          regexComments: regexConstants.RegexComments,
          regexPhone: regexConstants.RegexPhone,
          regexLetters: regexConstants.RegexLetters,
          regexRut: regexConstants.RegexRut,
          regexFileNameSimple: regexConstants.RegexFileNameSimple,
          regexFileNameComplex: regexConstants.RegexFileNameComplex,
        },
        otp: {
          status: challengesConstants.statusOtp,
        },
        updateProfile: {
          updateClaimantProfileStep: updateProfileConstants.claimant,
          updateWfUserProfileStep: updateProfileConstants.wfUser,
        },
        dashboard: {
          statusType: dashboardConstants.statusType,
          groups: dashboardConstants.groups,
        },
      };
    },
  },
};
