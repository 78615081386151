import allConstantsMixin from "@/mixins/allConstants";
import currentPaginationMixin from "@/mixins/currentPagination";

export default {
  mixins: [allConstantsMixin, currentPaginationMixin],
  data() {
    return {
      page: {
        loading: false,
      },
      alert: {
        show: false,
        message: "",
      },
      form: {
        isValid: false,
        processing: false,
      },
    };
  },
  methods: {
    showAlert(message) {
      this.alert.message = message;
      this.alert.show = true;
    },
    hideAlert() {
      this.alert.message = "";
      this.alert.show = false;
    },
    initSignalProcessing() {
      this.form.processing = true;
    },
    stopSignalProcessing() {
      this.form.processing = false;
    },
    initSignalLoading() {
      this.page.loading = true;
    },
    stopSignalLoading() {
      this.page.loading = false;
    },
    showInfoNotification(message) {
      this.$store.dispatch("notifications/addInfo", message);
    },
    showErrorNotification(message) {
      this.$store.dispatch("notifications/addError", message);
    },
    gotoView(target, params) {
      const resolved = this.$router.resolve({ name: target, params: params });
      if (this.$router.currentRoute.fullPath == resolved.href) return;

      this.$router.push({ name: target, params: params });
    },
    replaceView(target, params) {
      const resolved = this.$router.resolve({ name: target, params: params });
      if (this.$router.currentRoute.fullPath == resolved.href) return;

      this.$router.replace({ name: target, params: params });
    },
  },
  mounted() {
    if (this.$refs.initialFocus) {
      this.$refs.initialFocus.focus();
    }
  },
};
