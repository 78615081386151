const chanelCodes = {
    claimantApp: "RECLAMANTE",
    ddcApp: "DDC",
    bankApp: "BANCO",
};

const creationSteps = {
    previousStep: 0,
    collectBasicData: 1,
    collectAddressData: 2,
    collectInstitutionData: 3,
    collectProducts: 4,
    collectRequestData: 5,
    collectDocumentsData: 6,
    voucher: 7
};

const documentStatuses = {
    loaded: "CARGADO",
    approvedFirstInstance: "APROBADO-PRIMERA-INSTANCIA",
    rejectedFirstInstance: "RECHAZADO-PRIMERA-INSTANCIA",
    approvedSecondInstance: "APROBADO-SEGUNDA-INSTANCIA",
    rejectedSecondInstance: "RECHAZADO-SEGUNDA-INSTANCIA",
    approvedByClaimant: "APROBADO-POR-RECLAMANTE",
    rejectedByClaimant: "RECHAZADO-POR-RECLAMANTE"
};

const posibleActions = {
    create: "INGRESO",
    
    admitDDC: "ADMITIR-DDC",                                //ejecutivo DDC
    presentToBank: "PRESENTAR-BANCO",                       //ejecutivo DDC
    rejectByForm: "RECHAZAR-FORMA",                         //ejecutivo DDC
    rejectByDeep: "RECHAZAR-FONDO",                         //ejecutivo DDC
    assignDefender: "ASIGNAR",                              //ejecutivo DDC
    reAssignDefender: "REASIGNAR",                          //ejecutivo DDC
    rejectCompliance: "RECHAZAR-CUMPLIMIENTO",              //ejecutivo DDC
    closeClaim: "CERRAR-RECLAMO",                           //ejecutivo DDC
    silentClaim: "SOLUCIONAR-SILENCIO",                     //ejecutivo DDC

    regularize: "REGULARIZAR",                                                                  //reclamante,
    expireRegularize: "EXPIRAR",                                                                //regularización expirada
    resultSendPapersClient: "RESPUESTA-ANTECEDENTES-ADICIONALES-CLIENTE",                       //reclamante
    resultSendParallelPapersClient: "RESPUESTA-ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE",      //reclamante

    acceptFastSolution: "ACEPTAR-SOLUCION-RAPIDA",                  //administrador banco
    rejectFastSolution: "RECHAZAR-SOLUCION-RAPIDA",                 //administrador banco
    assignBankExecutive: "ASIGNAR-EJECUTIVO",                       //administrador banco
    sendCompliance: "ENVIAR-CUMPLIMIENTO",                          //administrador banco
    
    acceptAndPresentToBank: "ACEPTAR-PRESENTAR",                    //defensor DDC
    rejectByNotCompatible: "RECHAZAR-INCOMPATIBILIDAD",             //defensor DDC
    acceptPapers: "ACEPTAR-ANTECEDENTES",                           //defensor DDC
    rejectByDeepDefender: "RECHAZAR-FONDO-DEFENSOR",                //defensor DDC
    decisionInFavorOfBank: "FALLAR-BANCO",                          //defensor DDC
    decisionInFavorOfClient: "FALLAR-CLIENTE",                      //defensor DDC
    decisionInCompromise: "AVENIMIENTO",                            //defensor DDC
    sendPapersBank: "ENVIAR-ANTECEDENTES-ADICIONALES-BANCO",        //defensor DDC
    sendPapersClient: "ENVIAR-ANTECEDENTES-ADICIONALES-CLIENTE",    //defensor DDC
    acceptExtension: "ACEPTAR-PRORROGA",                            //defensor DDC
    rejectExtension: "RECHAZAR-PRORROGA",                           //defensor DDC
    sendPapersParallel: "ENVIAR-ANTECEDENTES-PARALELO",             //defensor DDC
    acceptCompromiseDefender: "ACEPTAR-AVENIMIENTO-DEFENSOR",
    

    sendPapers: "ENVIAR-ANTECEDENTES",                                  //ejecutivo banco
    requestTimeExtension: "SOLICITAR-PRORROGA",                         //ejecutivo banco
    resultSendPapersBank: "RESPUESTA-ANTECEDENTES-ADICIONALES-BANCO",    //ejecutivo banco
    resultSendParallelPapersBank: "RESPUESTA-ANTECEDENTES-ADICIONALES-PARALELO-BANCO"    //ejecutivo banco
};

const currentEventActions = {
    fastSolution: {
        claimantAccept: "ACEPTAR-PROPUESTA",
        claimantReject: "RECHAZAR-PROPUESTA",
        acceptCompromise: "ACEPTAR-AVENIMIENTO",
        RejectCompromise: "RECHAZAR-AVENIMIENTO",
    }
};

const wfStatusesForDisplay = {
    basedOnLastAction: [
        {action: "", textToDisplay: "Ingresado"},
        {action: "CREAR-RECLAMO", textToDisplay: "Ingresado"},
        {action: "ADMITIR-DDC", textToDisplay: "Defensor Asignado"},
        {action: "RECHAZAR-FONDO", textToDisplay: "Rechazado por Fondo"},
        {action: "RECHAZAR-FORMA", textToDisplay: "Rechazado por Forma"},
        {action: "REGULARIZAR", textToDisplay: "Regularizado"},
        {action: "PRESENTAR-BANCO", textToDisplay: "Tramitación Simplificada Pendiente"},       //revisar
        {action: "ACEPTAR-SOLUCION-RAPIDA", textToDisplay: "Tramitación simplificada"},         //currentEventActions.fastSolution.bankAccept
        {action: "RECHAZAR-SOLUCION-RAPIDA", textToDisplay: "Sin Tramitación Simplificada (Rechazada Banco)"},    //currentEventActions.fastSolution.bankReject
        {action: "ACEPTAR-PROPUESTA", textToDisplay: "Propuesta aceptada"},                     //currentEventActions.fastSolution.claimantAccept
        {action: "RECHAZAR-PROPUESTA", textToDisplay: "Propuesta rechazada"},                   //currentEventActions.fastSolution.claimantReject
        {action: "EXPIRAR-PROPUESTA", textToDisplay: "Sin Tramitación Simplificada (Expirada Cliente)"},           //
        {action: "EXPIRAR-SOLUCION-RAPIDA", textToDisplay: "Sin Tramitación Simplificada (Expirada Banco)"},     //
        {action: "EXPIRAR", textToDisplay: "No Regularizado"},                                  //Es un rechazo por forma expirado. El reclamante no lo regularizo dentro del plazo
        {action: "ACEPTAR-PRESENTAR", textToDisplay: "Presentado a Banco"},
        {action: "RECHAZAR-INCOMPATIBILIDAD", textToDisplay: "Incompatible"},
        {action: "ASIGNAR", textToDisplay: "Defensor Asignado"},
        {action: "REASIGNAR", textToDisplay: "Defensor Asignado"},
        {action: "ASIGNAR-EJECUTIVO", textToDisplay: "Ejecutivo Banco Asignado"},
        {action: "ENVIAR-ANTECEDENTES", textToDisplay: "Respondido por Banco"},
        {action: "ACEPTAR-ANTECEDENTES", textToDisplay: "Pendiente Resolución"},
        {action: "FALLAR-BANCO", textToDisplay: "Fallo Favor Banco"},
        {action: "FALLAR-CLIENTE", textToDisplay: "Fallo Favor Cliente"},
        {action: "ENVIAR-CUMPLIMIENTO", textToDisplay: "Cumplimiento Banco Enviado"},
        {action: "CERRAR-RECLAMO", textToDisplay: "Cerrado Favor Cliente"},
        {action: "RECHAZAR-CUMPLIMIENTO", textToDisplay: "Cumplimiento Banco Rechazado"},
        {action: "AVENIMIENTO", textToDisplay: "Avenimiento Pendiente Cliente"},
        {action: "ACEPTAR-AVENIMIENTO", textToDisplay: "Avenimiento Aceptado"},
        {action: "RECHAZAR-AVENIMIENTO", textToDisplay: "Avenimiento Rechazado"},
        {action: "EXPIRAR-RECHAZAR-AVENIMIENTO", textToDisplay: "Avenimiento Rechazado (Expirada Cliente)"},
        {action: "ENVIAR-ANTECEDENTES-ADICIONALES-BANCO", textToDisplay: "Antecedentes Adicionales Banco"},
        {action: "RESPUESTA-ANTECEDENTES-ADICIONALES-BANCO", textToDisplay: "Respuesta Adicional Banco"},
        {action: "EXPIRAR-ANTECEDENTES-ADICIONALES-BANCO", textToDisplay: "No Respondido por Banco"},
        {action: "ENVIAR-ANTECEDENTES-ADICIONALES-CLIENTE", textToDisplay: "Antecedentes Adicionales Cliente"},
        {action: "RESPUESTA-ANTECEDENTES-ADICIONALES-CLIENTE", textToDisplay: "Respuesta Adicional Cliente"},
        {action: "EXPIRAR-ANTECEDENTES-ADICIONALES-CLIENTE", textToDisplay: "No Respondido por Cliente"},
        {action: "EXPIRAR-SLA-15", textToDisplay: "No Respondido por Banco"},
        {action: "SOLICITAR-PRORROGA", textToDisplay: "Pendiente Prórroga"},
        {action: "ACEPTAR-PRORROGA", textToDisplay: "Prórroga Aceptada"},
        {action: "EXPIRAR-ACEPTAR-PRORROGA", textToDisplay: "Ejecutivo Banco Asignado"},
        {action: "RECHAZAR-PRORROGA", textToDisplay: "Prórroga Rechazada"},
        {action: "BANCO-NO-RESPONDE", textToDisplay: "No Respondido por Banco"},
        {action: "RECHAZAR-FONDO-DEFENSOR", textToDisplay: "Rechazado por Fondo Defensor"},
        {action: "ENVIAR-ANTECEDENTES-PARALELO", textToDisplay: "Antecedentes Adicionales Cliente y Banco"},

        {action: "RESPUESTA-ANTECEDENTES-ADICIONALES-PARALELO-BANCO", textToDisplay: "Antecedentes Adicionales Cliente y Banco"},
        {action: "RESPUESTA-ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE", textToDisplay: "Antecedentes Adicionales Cliente y Banco"},
        {action: "EXPIRAR-ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE", textToDisplay: "Antecedentes Adicionales Cliente y Banco"},
        {action: "EXPIRAR-ANTECEDENTES-ADICIONALES-PARALELO-BANCO", textToDisplay: "Antecedentes Adicionales Cliente y Banco"},

        {action: "RESPUESTA-ANTECEDENTES-ADICIONALES-PARALELO-BANCO-COMPLETADO", textToDisplay: "Respuesta Adicionales Cliente y Banco"},
        {action: "RESPUESTA-ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE-COMPLETADO", textToDisplay: "Respuesta Adicionales Cliente y Banco"},
        
        {action: "EXPIRAR-ANTECEDENTES-ADICIONALES-PARALELO-BANCO-COMPLETADO", textToDisplay: "Expira Respuesta Adicionales Cliente y Banco"},
        {action: "EXPIRAR-ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE-COMPLETADO", textToDisplay: "Expira Respuesta Adicionales Cliente y Banco"},
        
        {action: "MIGRAR-INHABILITAR-CLIENTE", textToDisplay: "Inhabilitado por Cliente"},
        {action: "MIGRAR-INFORMAR-CLIENTE", textToDisplay: "Defensoría Informa a Cliente"},
        {action: "MIGRAR-FINALIZADO", textToDisplay: "Caso Finalizado DF"},
        {action: "NEW-UPLOAD-FILE", textToDisplay: ""},
        {action: "SOLUCIONAR-SILENCIO", textToDisplay: "Solucionado por Silencio"},
        {action: "ACEPTAR-AVENIMIENTO-DEFENSOR", textToDisplay: "Avenimiento Aprobado Defensor"},
    ]
};

const rejectionByDeepReasonsCodes = {
    inCourt: "02",                          //Reclamo en Tribunales/CMF
    outOfBusiness: "03",                    //Fuera de Giro Bancario,
    requestCompensation: "04",              //Solicitud de Indemnización
    loanGranting: "05",                     //Ortorgamiento de Créditos
    notQuantifiableInMoney: "06",           //No Cuantificable en Dinero"
    outOfMoneyLimit: "07",                  //Excede Límite 600 UF
    appeal: "08",                           //Apelación
    extemporaneous: "09",                   //Extemporánea
    crime: "10"                             //Delito
};


export default {
    chanelCodes,
    creationSteps,
    documentStatuses,
    posibleActions,
    rejectionByDeepReasonsCodes,
    currentEventActions,
    wfStatusesForDisplay
};