<template>
  <div>
    <v-snackbar
      v-model="show"
      :multi-line="multiLine"
      :color="notification.type"
      :timeout="timeout"
    >
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text dark v-bind="attrs" @click="show = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show: true,
      multiLine: true,
      timeout: 10000, //5 segundos
    };
  },
  computed: {},
  methods: {
    close() {
      this.show = false;
      //this.$store.dispatch("notifications/remove", this.notification);
    },
  },
  watch: {
    show(newValue) {
      if (newValue == false)
        this.$store.dispatch("notifications/remove", this.notification);
    },
  },
};
</script>

<style lang="scss" scoped></style>
