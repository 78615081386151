export default {
    namespaced : true,
    state: {
        currentUpdateProfile: null
    },
    mutations: { 
        setCurrentUpdateProfile(state, updateProfile) {
            state.currentUpdateProfile = updateProfile;
        },
        deleteCurrentUpdateProfile(state) {
            state.currentUpdateProfile = null;
        },
    },
    actions: {  
        createOrUpdate({commit}, updateProfile) {
            commit("setCurrentUpdateProfile", updateProfile);
        },
        clear({commit}) {
            commit("deleteCurrentUpdateProfile");    
        },
    },
    getters: {        
        currentUpdateProfile(state) {
            return state?.currentUpdateProfile;
        }
    }
}