import Vue from "vue";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

class TraceService {
    constructor() {
        this.appInsights = new ApplicationInsights({config: {
            instrumentationKey: Vue.prototype.$Settings.all.applicationInsights.instrumentationKey,
            enableAutoRouteTracking: true
        }});
      
        const telemetryInitializer = (envelope) => {
            envelope.tags["ai.cloud.role"] = Vue.prototype.$Settings.all.applicationInsights.roleName;
        }

        this.appInsights.loadAppInsights();
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }

    getInstance() {
        return this.appInsights;
    }

    setCurrentUser(userName, userId, sessionId) {
        this.appInsights.setAuthenticatedUserContext(userName, userId, true);

        const telemetryInitializer = (envelope) => {
            envelope.tags["ai.session.id"] = sessionId;
        }

        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }

    deleteCurrentUser() {
        this.appInsights.clearAuthenticatedUserContext();
    }

    trackException(errorId, sourceInfo, errorMessage) {
        this.appInsights.trackException({
            exception: new Error(errorMessage),
            properties: { 
                errorId: errorId,
                sourceInfo: sourceInfo
              }
        });
    }
  }
  
  export default {
    install(Vue) {
        Vue.prototype.$Trace = new TraceService();
    }
}