
export default {
    namespaced : true,
    state: {
        currentClaim: null
    },
    mutations: { 
        setCurrentClaim(state, claim) {
            state.currentClaim = claim;
        },
        deleteCurrentClaim(state) {
            state.currentClaim = null;
        },
    },
    actions: {  
        createOrUpdate({commit}, claim) {
            commit("setCurrentClaim", claim);
        },
        clear({commit}) {
            commit("deleteCurrentClaim");    
        },
    },
    getters: {        
        currentClaim(state) {
            return state?.currentClaim;
        }
    }
}