import Vue from 'vue'
import settingsService from "@/services/settings/middlewareSettings";
import parametersService from "@/services/settings/parameters";

class SettingsService {
    constructor(allSettings) {
        this.allSettings = allSettings;
    }
    get all() {
        return this.allSettings;
    }
}

class ParametersService {
    constructor(parameters) {
        this.allParameters = parameters;
    }
    get all() {
        return this.allParameters;
    }
}

function runGetSettings(callback) {
    const commandGetSettingsFromMiddleware = `${process.env.VUE_APP_SETTINGS_GET_FROM_MIDDLEWARE_API}`;
    const getSettingsFromMiddleware = (commandGetSettingsFromMiddleware === 'true');

    if (getSettingsFromMiddleware) {

        settingsService.getAll().then(allSettings => {
            Vue.prototype.$Settings = new SettingsService(allSettings);

            parametersService.getAll(allSettings.coreApi.url).then(allParameters => {
                Vue.prototype.$Parameters = new ParametersService(allParameters);
                callback();
            });
        });

    }
    else {

        const excecutionMode = `${process.env.VUE_APP_EXECUTION_MODE}`

        const settingsApplicationInsights = {
            instrumentationKey: `${process.env.VUE_APP_APPINSIGHTS_INSTANCE}`,
            roleName: `${process.env.VUE_APP_APPINSIGHTS_ROLE_NAME}`
        };

        const settingsCoreApi = {
            url: `${process.env.VUE_APP_API_CORE}`,
            key: ""
        };

        const settingsAccountsApi = {
            url: `${process.env.VUE_APP_API_ACCOUNTS}`,
            key: ""
        };

        const settingsChallengesApi = {
            url: `${process.env.VUE_APP_API_CHALLENGES}`,
            key: `${process.env.VUE_APP_API_CHALLENGES_KEY}`
        };

        const settingsSessionStorage = {
            encrypt: `${process.env.VUE_APP_ENCRYPT_SESSION_STORAGE}`,
            encryptionKey: `${process.env.VUE_APP_ENCRYPT_SESSION_STORAGE_KEY}`
        };

        const allSettings = {
            excecutionMode: excecutionMode,
            jwt: "12345",
            applicationInsights: settingsApplicationInsights,
            coreApi: settingsCoreApi,
            accountsApi: settingsAccountsApi,
            challengesApi: settingsChallengesApi,
            sessionStorage: settingsSessionStorage
        };

        Vue.prototype.$Settings = new SettingsService(allSettings);
        
        parametersService.getAll(allSettings.coreApi.url).then(allParameters => {
            Vue.prototype.$Parameters = new ParametersService(allParameters);
            callback();
        });
    }
}

export default { 
    get(callback) {
        runGetSettings(callback);
    }
};