//Default locale
const defaultLocale = "es-CL";
const defaultThousandSeparatorToShow = "."; //separador decimal que se desea para el formato de salida 
const defaultCurrencyCodeToShow = "CLP";    //ISO currency code https://es.wikipedia.org/wiki/ISO_4217

export default {
    formatNumber(numberString, locale) {
        return new Intl.NumberFormat(locale ? locale : defaultLocale).format(numberString)
    },
    formatClassicNumber(numberString, locale) {
        const preFormatNumber = new Intl.NumberFormat(locale ? locale : defaultLocale).format(numberString)
        return preFormatNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + defaultThousandSeparatorToShow)
    },
    formatCurrencyNumber(numberString, locale, currencyCode) {
        const formatSpecification = {
            style: 'currency', 
            currency: currencyCode ? currencyCode : defaultCurrencyCodeToShow
        };

        return new Intl.NumberFormat(locale ? locale : defaultLocale, formatSpecification).format(numberString)
    },
    formatClassicCurrencyNumber(numberString, locale, currencyCode) {
        const formatSpecification = {
            style: 'currency', 
            currency: currencyCode ? currencyCode : defaultCurrencyCodeToShow
        };

        const preFormatNumber = new Intl.NumberFormat(locale ? locale : defaultLocale, formatSpecification).format(numberString)
        return preFormatNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + defaultThousandSeparatorToShow)
    },
}
