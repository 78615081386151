import axios from "axios";
import axiosRetry from "axios-retry";

const middlewareApiUrlMode = `${process.env.VUE_APP_SETTINGS_MIDDLEWARE_API_MODE}`;
let middlewareApiUr = `${process.env.VUE_APP_SETTINGS_MIDDLEWARE_API_URL}`;

if (middlewareApiUrlMode === "DYNAMIC") {
  middlewareApiUr = window.location.origin;
}

const apiClientMiddlewareSettings = axios.create({
  baseURL: `${middlewareApiUr}/spasettings`,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

axiosRetry(apiClientMiddlewareSettings, { 
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  }
});

export default {
    getAll() {
      return new Promise((resolve) => {
        apiClientMiddlewareSettings.post("/")
        .then(result => {
          resolve({
            excecutionMode: result.data.excecutionMode,
            jwt: result.data.jwt,
            applicationInsights: result.data.applicationInsights,
            coreApi: result.data.coreApi,
            accountsApi: result.data.accountsApi,
            challengesApi: result.data.challengesApi,
            sessionStorage: result.data.sessionStorage,
          });
        });
      });
    }
}