const userRoles = {
    claimant: "Claimant",
    ddcExecutive: "DDCExecutive",
    ddcDefender: "DDCDefender",
    bankAdministrator: "BankAdministrator",
    bankExecutive: "BankExecutive",
    wfAdministrator: "WFAdministrator",
    engine: "Motor"
};

const userRolesCodes = {
    claimant: 1,
    ddcExecutive: 2,
    ddcDefender: 3,
    bankAdministrator: 4,
    bankExecutive: 5,
    wfAdministrator: 6,
    engine: 7
};

const userTypes = {
    person: 1,
    business: 2
};

const loginResultCode = {
    Success: 0,
    SuccessAndMustChangePassword: 1,
    CredentialDoesNotExist: 2,
    CredentialLocked: 3,
    CredentialExpired: 4,
    WrongPassword: 5,
    TokenExpired: 6
};

const changePasswordResultCode = {
    Success: 0,
    CredentialDoesNotExist: 1,
    WrongCurrentPassword: 2,
    NewPasswordInvalidMinimunLenght: 3,
    NewPasswordInvalidMaximumLenght: 4,
    NewPasswordInvalidPattern: 5,
    NewPasswordUsedPreviously: 6
};

export default {
    userRoles,
    userRolesCodes,
    userTypes,
    loginResultCode,
    changePasswordResultCode
};