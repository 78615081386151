const statusType = {
  wf: "wf",
  macro: "macro",
};

const groups = {
  claimant: {
    claimsActive: {
      statusType: statusType.macro,
      statuses: [
        "INGRESADO",
        "INGRESADO-DDC",
        "INGRESADO-BANCO-ADMIN",
        "ENREVISION",
        "REGULARIZADO",
      ],
    },
    claimsSolved: {
      statusType: statusType.macro,
      statuses: [
        "RESUELTO",
        "RESUELTO-BANCO",
        "RESUELTO-DEFENSOR",
        "RESUELTO-FALLO-BANCO",
        "RESUELTO-FALLO-CLIENTE",
        "RESUELTO-AVENIMIENTO",
      ],
    },
    queriesActive: {
      statusType: statusType.macro,
      statuses: ["INGRESADO", "PENDIENTE"],
    },
    queriesSolved: {
      statusType: statusType.macro,
      statuses: ["RESUELTO", "FINALIZADO"],
    },
  },
  ddcExecutive: {
    //Por revisar
    claimsToAdmit: {
      statusType: statusType.wf,
      statuses: [
        "PENDIENTE-ADMISION",
        "REGULARIZADO",
        "CUMPLIMIENTO-ENVIADO",
        "CUMPLIMIENTO-RECHAZADO-BANCO",
        "TRAMITACION-SIMPLIFICADA-PROPUESTA-RECHAZADA",
        "TRAMITACION-SIMPLIFICADA-PROPUESTA-EXPIRADA",
        "TRAMITACION-SIMPLIFICADA-RECHAZADA",
        "TRAMITACION-SIMPLIFICADA-EXPIRADA",
      ],
      wfStateType: "claimsToAdmit",
    },
    //Por asignar
    claimsPendingAssignment: {
      statusType: statusType.wf,
      statuses: ["ADMITIDO-DDC", "RECHAZADO-INCOMPATIBILIDAD-DEFENSOR"],
      wfStateType: "claimsPendingAssignment",
    },
    //Antecedentes adicionales
    claimsSendPapers: {
      statusType: statusType.wf,
      statuses: [
        "ANTECEDENTES-ADICIONALES-CLIENTE",
        "ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE",
        "ANTECEDENTES-ADICIONALES-BANCO",
        "ANTECEDENTES-ADICIONALES-PARALELO-BANCO",
      ],
      wfStateType: "claimsSendPapers",
    },
    //Pendiente Cliente
    claimsPendingClient: {
      statusType: statusType.wf,
      statuses: [
        "RECHAZADO-FORMA",
        "TRAMITACION-SIMPLIFICADA-ACEPTADA",
        "AVENIMIENTO-PENDIENTE-CLIENTE",
      ],
      wfStateType: "claimsPendingClient",
    },
    //Pendiente Banco
    claimsPendingBank: {
      statusType: statusType.wf,
      statuses: [
        "PRESENTADO-BANCO",
        "FALLO-FAVOR-CLIENTE",
        "PRESENTADO-BANCO-DEFENSOR",
        "EJECUTIVO-BANCO-ASIGNADO",
        "PRORROGA-ACEPTADA",
        "PRORROGA-RECHAZADA",
      ],
      wfStateType: "claimsPendingBank",
    },
    //Pendiente Defensor
    claimsPendingDefender: {
      statusType: statusType.wf,
      statuses: [
        "PENDIENTE-DEFENSOR",
        "PRORROGA-SOLICITADA",
        "ANTECEDENTES-ENVIADOS",
        "BANCO-NO-RESPONDE",
        "PENDIENTE-RESOLUCION",
        "AVENIMIENTO-RECHAZADO",
        "AVENIMIENTO-RECHAZADO-SLA",
      ],
      wfStateType: "claimsPendingDefender",
    },
    //Cerrados
    claimsClosed: {
      statusType: statusType.macro,
      statuses: [
        "RESUELTO",
        "RESUELTO-BANCO",
        "RESUELTO-DEFENSOR",
        "RESUELTO-FALLO-BANCO",
        "RESUELTO-FALLO-CLIENTE",
        "RESUELTO-AVENIMIENTO",
      ],
      wfStateType: "claimsClosed",
    },
    claimsReport: {
      statusType: statusType.macro,
      statuses: [
        "ENREVISION",
        "REGULARIZADO",
        "INGRESADO",
        "INGRESADO-DDC",
        "INGRESADO-BANCO-ADMIN",
        "INGRESADO-BANCO-EJECUTIVO",
        "RESUELTO",
        "RESUELTO-BANCO",
        "RESUELTO-DEFENSOR",
        "RESUELTO-FALLO-BANCO",
        "RESUELTO-FALLO-CLIENTE",
        "RESUELTO-AVENIMIENTO",
      ],
      wfStateType: "claimsReport",
    },

    queriesPending: {
      statusType: statusType.macro,
      statuses: ["INGRESADO", "PENDIENTE"],
      wfStateType: "queriesPending",
    },
    queriesClosed: {
      statusType: statusType.macro,
      statuses: ["RESUELTO", "FINALIZADO"],
      wfStateType: "queriesClosed",
    },
  },
  ddcDefender: {
    //Asignados --> Pendiente defensor
    claimsAssigned: {
      statusType: statusType.wf,
      statuses: [
        "PENDIENTE-DEFENSOR",
        "PRORROGA-SOLICITADA",
        "ANTECEDENTES-ENVIADOS",
        "BANCO-NO-RESPONDE",
        "PENDIENTE-RESOLUCION",
        "AVENIMIENTO-RECHAZADO",
        "AVENIMIENTO-RECHAZADO-SLA",
      ],
      wfStateType: "claimsAssigned",
    },
    //Incompatibles, --> OKAY
    claimsNotCompatible: {
      statusType: statusType.wf,
      statuses: ["RECHAZADO-INCOMPATIBILIDAD-DEFENSOR"],
      wfStateType: "claimsNotCompatible",
    },

    //Antecedentes adicionales
    claimsSendPapers: {
      statusType: statusType.wf,
      statuses: [
        "ANTECEDENTES-ADICIONALES-CLIENTE",
        "ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE",
        "ANTECEDENTES-ADICIONALES-BANCO",
        "ANTECEDENTES-ADICIONALES-PARALELO-BANCO",
      ],
      wfStateType: "claimsSendPapers",
    },

    //Pendiente Cliente
    claimsPendingClient: {
      statusType: statusType.wf,
      statuses: ["AVENIMIENTO-PENDIENTE-CLIENTE"],
      wfStateType: "claimsPendingClient",
    },

    //Pendiente Banco
    claimsPendingBank: {
      statusType: statusType.wf,
      statuses: [
        "PRESENTADO-BANCO-DEFENSOR",
        "EJECUTIVO-BANCO-ASIGNADO",
        "PRORROGA-ACEPTADA",
        "PRORROGA-RECHAZADA",
        "FALLO-FAVOR-CLIENTE",
        "CUMPLIMIENTO-RECHAZADO-BANCO",
      ],
      wfStateType: "claimsPendingBank",
    },

    //Resueltos
    claimsClosed: {
      statusType: statusType.macro,
      statuses: [
        "RESUELTO-DEFENSOR",
        "RESUELTO-FALLO-BANCO",
        "RESUELTO-FALLO-CLIENTE",
        "RESUELTO-AVENIMIENTO",
      ],
      wfStateType: "claimsClosed",
    },
  },
  bankAdministrator: {
    //Ingresados banco
    claimsCreated: {
      statusType: statusType.macro,
      statuses: ["INGRESADO-BANCO-ADMIN", "INGRESADO-BANCO-EJECUTIVO"],
      wfStateType: "claimsCreated",
    },
    //por responder --> nuevo
    claimsForAnswer: {
      statusType: statusType.wf,
      statuses: [
        "PRESENTADO-BANCO",
        "PRESENTADO-BANCO-DEFENSOR",
        "EJECUTIVO-BANCO-ASIGNADO",
        "FALLO-FAVOR-CLIENTE",
        "CUMPLIMIENTO-RECHAZADO-BANCO",
        "PRORROGA-ACEPTADA",
        "PRORROGA-RECHAZADA",
      ],
      wfStateType: "claimsForAnswer",
    },
    //Prorrogables --> Se mantiene
    claimsInExtension: {
      statusType: statusType.wf,
      statuses: ["PRORROGA-SOLICITADA"],
      wfStateType: "claimsInExtension",
    },
    //Antecedentes adicionales
    claimsSendPapers: {
      statusType: statusType.wf,
      statuses: [
        "ANTECEDENTES-ADICIONALES-CLIENTE",
        "ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE",
        "ANTECEDENTES-ADICIONALES-BANCO",
        "ANTECEDENTES-ADICIONALES-PARALELO-BANCO",
      ],
      wfStateType: "claimsSendPapers",
    },
    //Pendiente cliente --> nuevo
    claimsPendingClient: {
      statusType: statusType.wf,
      statuses: [
        "TRAMITACION-SIMPLIFICADA-ACEPTADA",
        "AVENIMIENTO-PENDIENTE-CLIENTE",
      ],
      wfStateType: "claimsPendingClient",
    },
    //Pendiente Ejecutivo DDC --> nuevo
    claimsPendingDDC: {
      statusType: statusType.wf,
      statuses: [
        "TRAMITACION-SIMPLIFICADA-RECHAZADA",
        "TRAMITACION-SIMPLIFICADA-EXPIRADA",
        "TRAMITACION-SIMPLIFICADA-PROPUESTA-RECHAZADA",
        "TRAMITACION-SIMPLIFICADA-PROPUESTA-EXPIRADA",
        "CUMPLIMIENTO-ENVIADO",
      ],
      wfStateType: "claimsPendingDDC",
    },
    //Pendiente Defensor --> nuevo
    claimsPendingDefender: {
      statusType: statusType.wf,
      statuses: [
        "BANCO-NO-RESPONDE",
        "ANTECEDENTES-ENVIADOS",
        "PENDIENTE-RESOLUCION",
        "AVENIMIENTO-RECHAZADO",
        "AVENIMIENTO-RECHAZADO-SLA",
      ],
      wfStateType: "claimsPendingDefender",
    },
    //Cerrados
    claimsClosed: {
      statusType: statusType.macro,
      statuses: [
        "RESUELTO-DEFENSOR",
        "RESUELTO-BANCO",
        "RESUELTO-FALLO-BANCO",
        "RESUELTO-FALLO-CLIENTE",
        "RESUELTO-AVENIMIENTO",
      ],
      wfStateType: "claimsClosed",
    },
    claimsReport: {
      statusType: statusType.macro,
      statuses: [
        "BANCO-NO-RESPONDE",
        "INGRESADO-BANCO-ADMIN",
        "INGRESADO-BANCO-EJECUTIVO",
        "RESUELTO",
        "RESUELTO-BANCO",
        "RESUELTO-DEFENSOR",
        "RESUELTO-FALLO-BANCO",
        "RESUELTO-FALLO-CLIENTE",
        "RESUELTO-AVENIMIENTO",
        "BANCO-NO-RESPONDE",
      ],
      wfStateType: "claimsReport",
    },
  },
  bankExecutive: {
    //Ingresados // se mantiene
    claimsCreated: {
      statusType: statusType.macro,
      statuses: ["INGRESADO-BANCO-ADMIN", "INGRESADO-BANCO-EJECUTIVO"],
      wfStateType: "claimsCreated",
    },
    //por responder --> se mantiene
    claimsPendingResponse: {
      statusType: statusType.wf,
      statuses: [
        "PRESENTADO-BANCO-DEFENSOR",
        "EJECUTIVO-BANCO-ASIGNADO",
        "FALLO-FAVOR-CLIENTE",
        "CUMPLIMIENTO-RECHAZADO-BANCO",
        "PRORROGA-ACEPTADA",
        "PRORROGA-RECHAZADA",
      ],
      wfStateType: "claimsPendingResponse",
    },
    //Prorrogables --> Se mantiene
    claimsInExtension: {
      statusType: statusType.wf,
      statuses: ["PRORROGA-SOLICITADA"],
      wfStateType: "claimsInExtension",
    },
    //Antecedentes adicionales
    claimsSendPapers: {
      statusType: statusType.wf,
      statuses: [
        "ANTECEDENTES-ADICIONALES-CLIENTE",
        "ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE",
        "ANTECEDENTES-ADICIONALES-BANCO",
        "ANTECEDENTES-ADICIONALES-PARALELO-BANCO",
      ],
      wfStateType: "claimsSendPapers",
    },
    //Pendiente cliente --> nuevo
    claimsPendingClient: {
      statusType: statusType.wf,
      statuses: ["AVENIMIENTO-PENDIENTE-CLIENTE"],
      wfStateType: "claimsPendingClient",
    },

    //Pendiente Ejecutivo DDC --> nuevo
    claimsPendingDDC: {
      statusType: statusType.wf,
      statuses: ["CUMPLIMIENTO-ENVIADO"],
      wfStateType: "claimsPendingDDC",
    },
    //Pendiente Defensor --> nuevo
    claimsPendingDefender: {
      statusType: statusType.wf,
      statuses: [
        "BANCO-NO-RESPONDE",
        "ANTECEDENTES-ENVIADOS",
        "PENDIENTE-RESOLUCION",
        "AVENIMIENTO-RECHAZADO",
        "AVENIMIENTO-RECHAZADO-SLA",
      ],
      wfStateType: "claimsPendingDefender",
    },
    //Cerrados
    claimsClosed: {
      statusType: statusType.macro,
      statuses: [
        "RESUELTO-FALLO-BANCO",
        "RESUELTO-FALLO-CLIENTE",
        "RESUELTO-AVENIMIENTO",
      ],
      wfStateType: "claimsClosed",
    },
  },
};

export default {
  statusType,
  groups,
};
