import claimsConstants from "@/constants/claims";

export default {
    wfStatusFromAction(lastActionCode) {
        const actionAndDescription = claimsConstants.wfStatusesForDisplay.basedOnLastAction.find(action => action.action == lastActionCode);
        if (!actionAndDescription)
            return lastActionCode;
        else
            return actionAndDescription.textToDisplay;
    },
    wfStatusForClaimant(lastActionCode) {
        const actionAndDescription = claimsConstants.wfStatusesForDisplay.basedOnLastAction.find(action => action.action == lastActionCode);
        if (!actionAndDescription)
            return lastActionCode;
        else
            return actionAndDescription.textToDisplay;
    }
}