export default {
    namespaced : true,
    state: {
        actualRecoveryPassword: null
    },
    mutations: { 
        setRecoveryPassword(state, recoveryData) {
            state.actualRecoveryPassword = recoveryData;
        },
        deleteRecoveryPassword(state) {
            state.actualRecoveryPassword = null;
        },
    },
    actions: {  
        createOrUpdate({commit}, recoveryData) {
            commit("setRecoveryPassword", recoveryData);
        },
        clear({commit}) {
            commit("deleteRecoveryPassword");    
        },
    },
    getters: {        
        currentRecoveryPassword(state) {
            return state?.actualRecoveryPassword;
        }
    }
}