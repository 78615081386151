<template>
    <div class="notificacions-container">
        <NotificationAlert
             v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
        />   
    </div>
</template>

<script>
    import NotificationAlert from "@/components/NotificationAlert.vue";
    
    export default {
        components: {
            NotificationAlert
        },
        computed: {
            notifications() {
                return this.$store.state.notifications.notifications;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>