export default {
    namespaced : true,
    state: {
        actualVerifyPhone: null
    },
    mutations: { 
        setVerifyPhone(state, recoveryData) {
            state.actualVerifyPhone = recoveryData;
        },
        deleteVerifyPhone(state) {
            state.actualVerifyPhone = null;
        },
    },
    actions: {  
        createOrUpdate({commit}, recoveryData) {
            commit("setVerifyPhone", recoveryData);
        },
        clear({commit}) {
            commit("deleteVerifyPhone");    
        },
    },
    getters: {        
        currentVerifyPhone(state) {
            return state?.actualVerifyPhone;
        }
    }
}