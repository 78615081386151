export default {
    namespaced : true,
    state: {
        currentRegistration: null
    },
    mutations: { 
        setCurrentRegistration(state, registration) {
            state.currentRegistration = registration;
        },
        deleteCurrentRegistration(state) {
            const emtpyNewRegistration = {
                userType: state.currentRegistration.userType
            };
            state.currentRegistration = emtpyNewRegistration;
        },
    },
    actions: {  
        createOrUpdate({commit}, registration) {
            commit("setCurrentRegistration", registration);
        },
        clear({commit}) {
            commit("deleteCurrentRegistration");    
        },
    },
    getters: {        
        currentRegistration(state) {
            return state?.currentRegistration;
        }
    }
}