import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import router from "@/router/index";
import store from "@/store/index";

import IdleVue from "idle-vue";
import VueCookies from "vue-cookies";
import UUID from "vue-uuid";
import { uuid } from "vue-uuid";

//Filters
import customFilters from "@/filters/index";

//Plugins
import authenticationCustom from "@/plugins/customauthentication";
import trace from "@/plugins/trace";

import VueMobileDetection from "vue-mobile-detection";

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

//Configure Vue
Vue.use(VueCookies);
Vue.use(UUID);
Vue.use(trace);
Vue.use(customFilters);
Vue.use(VueMobileDetection);

//Configure Session Timeout
var secondsTimeout = 0;
if (Vue.prototype.$Settings.all.excecutionMode == "FRONT") {
  secondsTimeout = parseInt(
    `${process.env.VUE_APP_SESSION_TIMEOUT_INSECONDS_FOR_FRONT}`
  );
}

if (Vue.prototype.$Settings.all.excecutionMode == "WF") {
  secondsTimeout = parseInt(
    `${process.env.VUE_APP_SESSION_TIMEOUT_INSECONDS_FOR_WF}`
  );
}

Vue.use(IdleVue, {
  idleTime: secondsTimeout * 1000,
  events: ["keydown", "mousedown"],
  store: store,
  startAtIdle: false,
});

//Inject custom authentication provider
Vue.use(authenticationCustom);

//Catch and trace global errors
Vue.config.errorHandler = function(err, sourceComponent, sourceInfo) {
  if (!err) return;

  console.log("----- Begin error global VUE captured -----");
  console.log("sourceInfo:", sourceInfo);
  console.log("sourceComponent:", sourceComponent);
  console.log("err:", err);
  console.log("----- End error global VUE captured -----");

  const errorId = uuid.v4();
  Vue.prototype.$Trace.trackException(errorId, sourceInfo, err);
};

//Final section. Create Vue App
Vue.config.productionTip = false;
new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
