<template>
  <div></div>
</template>

<script>
import Vue from "vue";
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  computed: {
    errorMiddleware() {
      return this.$store.state.notifications.errorMiddleware;
    },
  },
  watch: {
    errorMiddleware(newValue) {
      if (newValue == true) {
        this.internalLogout();
        this.$store.dispatch("notifications/setMiddleware", false);

        if (Vue.prototype.$Settings.all.excecutionMode == "FRONT") {
          this.gotoView("begin");
        }

        if (Vue.prototype.$Settings.all.excecutionMode == "WF") {
          this.gotoView("login");
        }

        setTimeout(() => (window.location = window.location.href), 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
