<template>
  <v-app>
    <v-main :class="{ 'fondo-gris': applyBackground }">
      <v-dialog v-model="currentSessionIsTimedOut" persistent width="400">
        <v-card elevation="2">
          <v-card-title class="text-h3"
            >Atención <v-spacer />
            <v-icon x-large color="red darken-2">
              mdi-alarm-light
            </v-icon></v-card-title
          >
          <v-card-text>
            Se ha superado el tiempo permitido de inactividad. Será direccionado
            al inicio.</v-card-text
          >
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="forceExit()">Entendido</v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <router-view></router-view>
      <NotificationsContainer />
      <ErrorMiddleware />
    </v-main>
  </v-app>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";

import NotificationsContainer from "@/components/NotificationsContainer.vue";
import ErrorMiddleware from "@/components/ErrorMiddleware.vue";

import authenticationConstants from "@/plugins/authenticationConstants";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  name: "App",
  components: {
    NotificationsContainer,
    ErrorMiddleware,
  },
  computed: {
    applyBackground() {
      if (!this.currentUserIsAuthenticated) return false;

      if (
        this.currentUserInfo.roles[0] !=
        authenticationConstants.userRolesCodes.claimant
      )
        return true;

      return false;
    },
    isTimeout() {
      return (
        this.$store.state.idleVue.isIdle && this.currentUserIsAuthenticated
      );
    },
  },
  watch: {
    isTimeout: function(newValue) {
      if (newValue) {
        this.markSessionAsTimedOut();
      }
    },
  },
  methods: {
    forceExit() {
      if (this.$Settings.all.excecutionMode == "FRONT") {
        this.gotoView("logoutfront");
      }

      if (this.$Settings.all.excecutionMode == "WF") {
        this.gotoView("logout");
      }
    },
  },
};

// @START HACKING
(function() {
  // Obtenemos la definicion de la funcion original
  let prop = Object.getOwnPropertyDescriptor(
    Intl.NumberFormat.prototype,
    "format"
  );

  // Sobrescribimos el método "format"
  Object.defineProperty(Intl.NumberFormat.prototype, "format", {
    get: function() {
      return function(value) {
        let fn = prop.get.call(this), // Recuperamos la funcion "formateadora" original
          opts = this.resolvedOptions(); // Obtenemos las opciones de "formateo"

        // Solo cambiamos el formateo cuando es moneda en español y el numero es >= 1.000 o menor a 10.000
        if (
          (opts.style == "currency" || opts.style == "decimal") &&
          opts.locale.substr(0, 2) == "es" &&
          opts.numberingSystem == "latn" &&
          value >= 1000 &&
          value < 10000
        ) {
          let num = fn(10000), // -> [pre]10[sep]000[sub]
            pre = num.substr(0, num.indexOf("10")),
            sep = num.substr(num.indexOf("10") + 2, 1),
            sub = num.substr(num.indexOf("000") + 3);
          num = value.toString();
          if (opts.style == "currency")
            return pre + num.slice(0, 1) + sep + num.slice(1) + sub;
          else return num.slice(0, 1) + sep + num.slice(1) + sub;
        }
        // Sino devolvemos el número formateado normalmente
        return fn(value);
        //return value;
      };
    },
  });
})();
</script>

<style lang="scss">
.fondo-gris {
  background-color: #f5f5f5 !important;
}

.btn-main {
  color: white !important;
  background-color: #199bca !important;
  width: 180px !important;
  padding: 0.5rem 1rem !important;
  font-size: 1.25rem !important;
  line-height: 1.5 !important;
  border-radius: 0.3rem !important;
  text-align: center !important;
  vertical-align: middle !important;
  text-transform: capitalize !important;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
}

.btn {
  text-transform: capitalize !important;
  font-size: 1.25rem !important;
  font-family: "Open Sans", Helvetica, Arial, sans-serif !important;
  line-height: 1.5 !important;
}

.menu-account-name {
  color: #199bca !important;
}

.current-steper {
  color: #199bca !important;
}

.time-alert-red {
  background-color: red;
}

.time-alert-green {
  background-color: green;
}

.time-alert-yellow {
  background-color: yellow;
}
</style>
