export default {
    computed: {
        currentPage() {
            return this.$store.getters["currentPagination/currentPage"];
        },
    },
    methods: {
        createOrUpdateCurrentPage(pageInfo) {
            return this.$store.dispatch("currentPagination/createOrUpdate", pageInfo);
        },
        clearCurrentPage() {
            return this.$store.dispatch("currentPagination/clear");
        },
    }
};