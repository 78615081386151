const chanelCodes = {
  claimantApp: "RECLAMANTE",
  ddcApp: "DDC",
};

const creationSteps = {
  previousStep: 0,
  collectBasicData: 1,
  collectInstitutionData: 2,
  collectRequestData: 3,
  voucher: 4,
};

const documentStatuses = {
  loaded: "CARGADO",
};

const posibleActions = {
  create: "INGRESO",
  closeQuery: "CERRAR-CONSULTA", //ejecutivo DDC
};

const currentEventActions = {
  fastSolution: {
    claimantAccept: "ACEPTAR-PROPUESTA",
    claimantReject: "RECHAZAR-PROPUESTA",
    acceptCompromise: "ACEPTAR-AVENIMIENTO",
    RejectCompromise: "RECHAZAR-AVENIMIENTO",
  },
};

const wfStatusesForDisplay = {
  basedOnLastAction: [
    { action: "", textToDisplay: "Ingresado" },
    { action: "CERRAR-CONSULTA", textToDisplay: "Respondida" },
  ],
};

const rejectionByDeepReasonsCodes = {
  inCourt: "02", //Reclamo en Tribunales/CMF
  outOfBusiness: "03", //Fuera de Giro Bancario,
  requestCompensation: "04", //Solicitud de Indemnización
  loanGranting: "05", //Ortorgamiento de Créditos
  notQuantifiableInMoney: "06", //No Cuantificable en Dinero"
  outOfMoneyLimit: "07", //Excede Límite 600 UF
  appeal: "08", //Apelación
  extemporaneous: "09", //Extemporánea
  crime: "10", //Delito
};

export default {
  chanelCodes,
  creationSteps,
  documentStatuses,
  posibleActions,
  rejectionByDeepReasonsCodes,
  currentEventActions,
  wfStatusesForDisplay,
};
