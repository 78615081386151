import dateFilters from "./dateFilters";
import numberFilters from "./numberFilters";
import rutFilters from "./rutFilters";
import claimWfStatusFilters from "./claimWfStatusFilters";
import mailFilters from "./mailFilters";

export default {
    install(Vue) {
        Vue.filter("formatDateShort", dateFilters.formatDateShort);
        Vue.filter("formatDateFull", dateFilters.formatDateFull);
        Vue.filter("formatDateLongFull", dateFilters.formatDateLongFull);
        Vue.filter("formatDateCustom", dateFilters.formatDateCustom);
        Vue.filter("formatTimeShort", dateFilters.formatTimeShort);
        Vue.filter("formatTimeShortWithSeconds", dateFilters.formatTimeShortWithSeconds);
        Vue.filter("formatDaysFromHours", dateFilters.formatDaysFromHours);

        Vue.filter("formatNumber", numberFilters.formatNumber);
        Vue.filter("formatClassicNumber", numberFilters.formatClassicNumber);
        Vue.filter("formatCurrencyNumber", numberFilters.formatCurrencyNumber);
        Vue.filter("formatClassicCurrencyNumber", numberFilters.formatClassicCurrencyNumber);

        Vue.filter("formatRut", rutFilters.formatRut);
        Vue.filter("formatRutAndDigit", rutFilters.formatRutAndDigit);

        Vue.filter("wfStatusFromAction", claimWfStatusFilters.wfStatusFromAction);
        Vue.filter("wfStatusForClaimant", claimWfStatusFilters.wfStatusForClaimant);  
        Vue.filter("maskEmail", mailFilters.mask);
    }
}